

@media screen and (max-width: 599px) {
    .Footer{
        width: 100%;
        height: auto;
        background-image: url('../../Images/Darktexture.jpg');
        padding: 10px;
        margin-bottom: -25px;
    }
    .Social-media-icons img{
        margin: 20px;
        width: 30px;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .footer-row {
        margin-left: 0;
        width: 100%;
    }
   
    .footer-row p{
        color: white;
        font-family: Poppins;
        font-size: 13px;
        line-height: 10px; 
    }
    .line{
        display: none;
    }
    .F-vector1{
        margin-right: 10px;
    }
}

@media screen and (min-width: 600px) {
    .Footer{
        width: 100%;
        height: 369px;
        background-image: url('../../Images/Darktexture.jpg');
        padding: 0px;
        margin-bottom: -25px;
        bottom: 0px;
        position: relative;
    }
    .Social-media-icons img{
     
        width: 30px;
        margin: 35px;
        margin-bottom: -5px;
        margin-top: 50px;
    }
 
    .footer-row {
        margin-left: 0;
        width: 100%;
        margin-top: 60px !important;
    }
   
    .footer-row p{
        color: white;
        font-family: Poppins;
        font-size: 13px;
        line-height: 10px; 
    }
    .line{
        display: none;
    }
    .F-vector1{
        margin-right: 2px;
    }
    .Footer-logo{
        margin-top: 30px !important;
    }
}

@media screen and (min-width: 768px) {
    .line{
        display: block;
        width: 80%;
        border: 4px solid #fff;
    }
}

.footer-row{
    color: white;
}

@media screen and (min-width: 900px) {
    .F-vector1{
        margin-right: 10px;
    }
}

@media screen and (min-width: 1200px) {
    .Footer{
        padding: 10px;
    }
    .footer-row{
        width: 1200px;
        margin: 0 auto;
        margin-top: 60px !important;
       
    }
    .Social-media-icons img{
        width: 50px;
        margin: 35px;
        margin-bottom: -5px;
        margin-top: 50px;
    }

    .footer-row p{
        font-size: 18px;
    }
    .Footer-logo{
        margin-top: 30px !important;
    }
}