@media screen and (max-width: 599px) {
    .navbar{
        height: auto;
        background: #FFFFFF;
        overflow: hidden;
        z-index: 100;
        position: fixed;
        top: 0;
        width: 100%;
    }
    
    .navbar ul li a{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 33px;
        /* identical to box height */
        color: #2C4F4B !important;
        margin: 20px;
    }
    
    
    
}



@media screen and (min-width: 600px) {
    .navbar{
        height: auto;
        background: #FFFFFF;
        overflow: hidden;
        z-index: 100;
        position: fixed;
        top: 0;
        width: 100%;
    }
    
    .navbar ul li a{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 33px;
        /* identical to box height */
        color: #2C4F4B !important;
        margin: 20px;
    }    
}

@media screen and (min-width: 900px) {
    .navbar{
        height: 53px;
    }
}

@media screen and (min-width: 1024px) {
    .navbar ul li a{
        font-size: 16px;
        margin: 5px;
    }
}

@media screen and (min-width: 1200px) {
    .navbar-brand{
        margin-left: 100px;
    }
    .navbar ul li a{
        font-size: 16px;
        margin: 10px;
    }
}

