@media screen and (max-width: 599px) {
    .Product-page-banner{
        background: url('../../Images/ProductsBanner.png');
        background-size: cover;
        width: 100%;
        height: 293px;
        position: relative;
        margin-top: 53px;
    }

    .Product-page-banner h1{
        font-family: Playfair Display;
        font-style: normal;
        font-weight: bold;
        font-size: 35px;
        line-height: 40px;
        color: #FFFFFF;
    
        top: 50%;
        transform: translateY(-50%);
        position: relative;
    }

    .Product-page-text{
        font-family: Poppins;
        font-size: 17px;
        width: 80%;
        margin: 0 auto;
        margin-top: 100px;
        margin-bottom: 100px;
        color: #063943;
    }
}


@media screen and (min-width: 600px) {
    .Product-page-banner{
        background: url('../../Images/ProductsBanner.png');
        background-size: cover;
        width: 100%;
        height: 345px;
        position: relative;
        margin-top: 53px;
    }

    .Product-page-banner h1{
        font-family: Playfair Display;
        font-style: normal;
        font-weight: 800;
        font-size: 60px;
        line-height: 40px;
        color: #FFFFFF;
        top: 50%;
        transform: translateY(-50%);
        position: relative;
    }

    .Product-page-text{
        font-family: Poppins;
        font-size: 20px;
        width: 80%;
        margin: 0 auto;
        margin-top: 100px;
        margin-bottom: 100px;
        color: #063943;
    }
}

@media screen and (min-width: 1200px) {
    .Product-page-text{
        width: 920px;
        margin: 0 auto;
        margin-top: 100px;
        margin-bottom: 100px;
    }
}