@media screen and (max-width: 599px) {
    .back-btn{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 45px;
        color: #2C4F4B;
        margin-top: 60px;
        text-align: start;
    }
   .a-tag:hover {
       text-decoration: none;
   }
   .back-btn-section{
       width: 100px;
       margin-left: 80px;
   }


    .gallery-row{
        width: 100%;
        display: flex;
        margin: 0 auto;
        margin-top: 50px;
 
    }
    .gallery-row2{
        width: 100%;
        display: flex;
        margin: 0 auto;
        margin-top: 50px;
    }
    .Landscape img{
        width: 300px;
        height: auto;
        margin-bottom: 25px;
    }
    .Landscape1 img{
        width: 260px;
        height: 160px;
        margin-bottom: 25px;
    }
    .Potriate img{
        width: 250px;
        height: auto;
        margin-bottom: 25px;
    }
    .column {
        flex: 50%;
        padding: 5px;
    }
    #lightbox{
        position: fixed;
        z-index: 1000;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0, .8);
        display: none;
    }
    
    #lightbox img{
       border: 2px solid white;
       max-height: 100%;
       width: 90%;
    }
    
    #lightbox.active{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .Project-gallery img{
        cursor: pointer;
    }
}

@media screen and (min-width: 600px) {

    .back-btn{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 45px;
        color: #2C4F4B;
        margin-top: 60px;
        text-align: start;
    }
   .a-tag:hover {
       text-decoration: none;
   }
   .back-btn-section{
       width: 100px;
       margin-left: 80px;
   }


    .gallery-row{
        width: 500px;
        display: flex;
        margin: 0 auto;
        margin-top: 100px;
 
    }
    .gallery-row2{
        width: 73%;
        display: flex;
        margin: 0 auto;
        margin-top: 100px;
    }
    .Landscape img{
        width: 250px;
        height: 160px;
        margin-bottom: 25px;
    }
    .Landscape1 img{
        width: 250px;
        height: 160px;
        margin-bottom: 25px;
    }
    .Potriate img{
        width: 200px;
        height: 313px;
        margin-bottom: 25px;
    }
    .column {
        flex: 30%;
        padding: 5px;
    }
    #lightbox{
        position: fixed;
        z-index: 1000;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0, .8);
        display: none;
    }
    
    #lightbox img{
       border: 2px solid white;
       max-height: 100%;
       width: auto;
    }
    
    #lightbox.active{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .Project-gallery img{
        cursor: pointer;
    }
}


@media screen and (min-width: 1200px) {
    .gallery-row{
        width: 900px;
        display: flex;
        margin: 0 auto;
        margin-top: 100px;
    }
    .gallery-row2{
        width: 1060px;
        display: flex;
        margin: 0 auto;
        margin-top: 100px;
    }
    .column {
        flex: 30%;
        padding: 5px;
    }


    .Landscape img{
        width: 500px;
        height: 320px;
        margin-bottom: 30px;
    }
    .Potriate img{
        width: 320px;
        height: 500px;
        margin-bottom: 30px;
    }

    .Landscape1 img{
        width: 500px;
        height: 320px;
        margin-bottom: 30px;
    }

    
   
}

