html,body{
    padding: 0;
    margin: 0;
    height: 100%;
}
body{
    overflow-x: hidden;
}
html {
  scroll-behavior: smooth;
}
#site-wrapper{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#page-content{
  flex: 1 0 auto;
} 


@media screen and (max-width: 599px) {
  
    .about-page-banner{
        width: 100%;
        height: 293px;
        background: url('../../Images/Bannerimage3.jpg');
        background-size: cover;
        margin-top: 53px;
    }
    .about-page-banner h1{
        font-family: Playfair Display;
        font-style: normal;
        font-weight: bold;
        font-size: 35px;
        line-height: 40px;
        color: #FFFFFF;
        top: 50%;
        transform: translateY(-50%);
        position: relative;
    }
    .about-page-content{
        margin-top: 7%;
        width: 80%;
        margin: 0 auto;
    }
    .about-page-content h1{
        font-family: Playfair Display;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 27px;
        color: #2C4F4B;
        text-transform: uppercase;
        margin-top: 12%;
        margin-bottom: 3%;
    }
    .about-page-content p{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 140%;
        text-align: center;
        color: #063943;
        margin-bottom: 30px;
    }
}


@media screen and (min-width: 600px) {
  
    .about-page-banner{
        width: 100%;  
        background: url('../../Images/Bannerimage3.jpg');
        background-size: cover;
        height: 400px;

    }
    .about-page-banner h1{
        font-family: Playfair Display;
        font-style: normal;
        font-weight: bold;
        font-size: 60px;
        line-height: 40px;
        color: #FFFFFF;
        top: 55%;
        transform: translateY(-50%);
        position: relative;
    }
    .about-page-content{
        margin-top: 7%;
        width: 80%;
        margin: 0 auto;
    }
    .about-page-content h1{
        font-family: Playfair Display;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
        color: #2C4F4B;
        text-transform: uppercase;
        margin-top: 5%;
        margin-bottom: 3%;
    }
    .about-page-content p{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        color: #063943;
    }
}

@media screen and (min-width: 1200px) {
  
    .about-page-content h1{
        font-size: 40px;
        line-height: 53px;
    }
    .about-page-content{
        margin-bottom: 5%;
        width: 1000px;
    }
}