@media screen and (max-width: 599px) {
    .Homepage-banner img{
      display: none;
    }
    .Homepage-banner{
        width: 100%;
        height: 414px;
        background-image: url('../../Images/MobilehomeBanner.png');
        background-size: cover ;
    }
    .Homepage-banner h1{
        font-family: Playfair Display;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 20px;
        /* identical to box height */
        color: #FFFFFF;
        margin-top: 52%;
        position: absolute;
        left: 0;
        right: 0;
    }

    .Pattren1,.Pattren2{
        display: none;
    }

    
    .home-about{
        position: relative;
        width: 100%;
        height: 230px;
        background-image: url('../../Images/About\ Us\ Section.png');
        margin-bottom: 4%;
        padding: 10px;
    }
    .home-about-text{
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
    .home-about p{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 140%;
        text-align: center;
        color: #063943;
        width: 85%;
        margin: 0 auto;
    }
    .Gulmoharsvg{
        width: 25%;
        margin-top: 20px;
    }
     /* Services section */
     .Services-section{
        margin-bottom: 10%;
        margin-top: -20px;
    }
    .carousel-row{
        width: 100%;
        margin: 0 auto;
        padding: 20px;
    }
  
    .carousel-left h2{
        background: #E4CCCB;
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        line-height: 30px;
        text-transform: uppercase;
        color: #063E49;
        width: 6rem;
        padding: 2px;
        text-align: start;
    }
    .carousel-left h1{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        /* identical to box height */
        color: #2C4F4B;
        text-align: start;
    }
    .carousel-left p{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 140%;
        text-align: start;
        color: #063943;
    }
    .carousel-right img{
       width: 88%;
    }
   

    .carousel-indicators li{
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: red;
    }

    .carousel-indicators{
        top: 96%;
    }

    .Gulmoharlotus{
        width: 100%;
    }

    /* Whyus Section */
    .whyus-section{
        width: 100%;
        height: auto;
        background-image: url('../../Images/Darktexture.jpg');
        padding: 15px;
        margin-bottom: 15%;
    }
    .whyus-section-content h1{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: 900;
        font-size: 35px;
        line-height: 43px;
        text-transform: uppercase;
        color: #E4CCCB;
        margin-bottom: 50px;
        margin-top: 50px;
    }
    .whyus-row-text h2{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: 900;
        font-size: 22px;
        line-height: 33px;
        text-transform: uppercase;
        color: #E4CCCB;
        margin-top: 2%;
        margin-bottom: 2%;
    }
    .whyus-row-text p{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 130%;
        text-align: center;
        color: #E4CCCB !important;
        width: 100%;
        margin: 0 auto;
    }

    .whyus-row-text{
        margin-bottom: 50px;
    }
    /* our work section */
  
    .ourwork-images{
        display: inline-block;
        margin-top: 40px;
    }
    .ourwork-images img{
        width: 250px;
        height: 350px;
        margin: 10px;
    }
    .ourwork-section-content h1{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: 900;
        font-size: 30px;
        line-height: 40px;
        text-transform: uppercase;
        color: #859A93;
    }
    
    .ourwork-icons{
        width: 25px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .view-more-btn{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 27px;
        color: #E1C7C6;
    }

    /* Testimonial Section */

    .Testimonial-section{
        margin-top: 5%;
        margin-bottom: 5%;
        width: 100%;
        background-image: url('../../Images/About\ Us\ Section.png');
        height: 250px;
    }

    .Testimonial-section h1{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
        text-transform: uppercase;
        color: #CA6C6A;
        margin-top: 20px !important;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
    .Testimonial-carousel-inner{
        height: 200px;
        width: 70%;
        margin: 0 auto;
    }
    .testimonial-text p{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 140%;
        text-align: center;
        color: #CA6C6A;
        margin-top: 17%;
    }
    .testimonial-text h5{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 140%;
        text-align: center;
        color: #CA6C6A;
    }
    .Testimonial-carousel{
        top: 20px;
    }

    /* Follows Us Section */
    .Followus-section h1{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
        text-transform: uppercase;
        color: #859A93;
    }
    .Followus-section p{
        font-family: Poppins;
        font-size: 13px;
        line-height: 15px;
        color: #859A93;
    }
    .followsus-icons img{
        margin: 10px;
        width: 30px;
        margin-top: -5px;
    }
    .follwus-section-images{
        display: inline-block;
        max-width: 600px;
    }

    .follwus-section-images img{
        width: 350px;
        margin: 10px;
    }
}

@media screen and (max-width: 375px) {
    .Homepage-banner{
        height: 380px;
    }

    .home-about p{
        font-size: 10px;
    }
    .whyus-section{
        width: 100%;
        height: auto;
        background-image: url('../../Images/Darktexture.jpg');
        padding: 15px;
        margin-bottom: 15%;
    }
    .whyus-section-content h1{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: 900;
        font-size: 30px;
        line-height: 43px;
        text-transform: uppercase;
        color: #E4CCCB;
        margin-bottom: 50px;
        margin-top: 50px;
    }
    .whyus-row-text h2{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        line-height: 33px;
        text-transform: uppercase;
        color: #E4CCCB;
        margin-top: 2%;
        margin-bottom: 2%;
    }
    .whyus-row-text p{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 130%;
        text-align: center;
        color: #E4CCCB !important;
    }

    
}


@media screen and (min-width: 600px) {

    .Homepage-banner img{
        width: 100%;
    }

    .Pattren1{
        width: 20%;
        z-index: 1;
        position: absolute;
        margin-top: -75px;
        margin-left: -50%;
    }
    .Pattren2{
        width: 220px;
        float: right;
        margin-top: -53px;
        position: relative;
        z-index: 1;
        margin-right: 5%;
    }

    .Homepage-banner h1{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 40px;
        /* identical to box height */
        color: #FFFFFF;
        margin-top: -18%;
        position: absolute;
        left: 0;
        right: 0;
    }
    .home-about{
        position: relative;
        width: 100%;
        height: 387px;
        background-image: url('../../Images/About\ Us\ Section.png');
       
     
  
    }
    .home-about-text{
        position: relative;
        top: 50%;
        transform: translateY(-50%);
  
    }
    .home-about p{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        color: #063943;
        width: 95%;
        margin: 0 auto;
    }

    .Gulmoharsvg{
        margin-top: 3%;
    }

    /* Services section */
    .Services-section{
        margin-bottom: 3%;
        margin-top: 2%;
    }
    .carousel-row{
        width: 100%;
        margin: 0 auto;
        padding: 20px;
    }
  
    .carousel-left h2{
        background: #E4CCCB;
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        text-transform: uppercase;
        color: #063E49;
        width: 8rem;
        padding: 5px;
        text-align: left;
    }
    .carousel-left-text h1{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        /* identical to box height */
        color: #2C4F4B;
        text-align: start;
    }
    
    .carousel-left p{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 140%;
        text-align: start;
        color: #063943;
    }
    .carousel-right img{
       width: 70%;
       
    }
   

    .carousel-indicators li{
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: red;
    }
   
    .Gulmoharlotus{
        width: 100%;
        z-index: 1;
        position: relative;
    }
    .TestimonialsLotus{
        position: relative;
        z-index: 999;
    }

    .carousel-inner .carousel-item {
        transition: -webkit-transform 1.5s ease;
        transition: transform 1.5s ease;
        transition: transform 1.5s ease, -webkit-transform 1.5s ease;
    }


    /* Whyus Section */
    .whyus-section{
        width: 100%;
        height: auto;
        background-image: url('../../Images/Darktexture.jpg');
        padding: 20px;
        margin-bottom: 5%;
    }
    .whyus-section-content h1{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: 900;
        font-size: 30px;
        line-height: 43px;
        text-transform: uppercase;
        color: #E4CCCB;
        margin-bottom: 81px;
    }
    .whyus-row-text h2{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: 900;
        font-size: 22px;
        line-height: 33px;
        text-transform: uppercase;
        color: #E4CCCB;
        margin-top: 2%;
        margin-bottom: 2%;
    }
    .whyus-row-text p{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 130%;
        text-align: center;
        color: #E4CCCB !important;

    }
    /* our work section */
    .ourwork-images{
        display: inline-block;
    }
    .ourwork-images img{
        width: 250px;
        height: 350px;
        margin: 20px;
    }
    .ourwork-section-content h1{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: 900;
        font-size: 30px;
        line-height: 53px;
        text-transform: uppercase;
        color: #859A93;
    }
    
    .ourwork-icons{
        width: 30px;
    }

    .view-more-btn{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #E1C7C6;
    }

    /* Testimonial Section */

    .Testimonial-section{
        margin-top: 5%;
        margin-bottom: 5%;
        width: 100%;
        background-image: url('../../Images/About\ Us\ Section.png');
        height: 250px;
    }

    .Testimonial-section h1{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
        text-transform: uppercase;
        color: #CA6C6A;
        margin-top: 20px !important;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
    .Testimonial-carousel-inner{
        height: 200px;
        width: 70%;
        margin: 0 auto;
    }
    .testimonial-text p{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 140%;
        text-align: center;
        color: #CA6C6A;
        margin-top: 17%;
    }
    .testimonial-text h5{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 140%;
        text-align: center;
        color: #CA6C6A;
    }
    .Testimonial-carousel{
        top: 20px;
    }

    /* Follows Us Section */
    .Followus-section h1{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
        text-transform: uppercase;
        color: #859A93;
    }
    .Followus-section p{
        font-family: Poppins;
        font-size: 13px;
        line-height: 15px;
        color: #859A93;
    }
    .followsus-icons img{
        margin: 15px;
        width: 30px;
        margin-top: -5px;
    }
    .follwus-section-images{
        margin-top: 5% !important;
        margin-bottom: 5% !important;
        width: 80%;
        margin: 0 auto;
    }

    .follwus-section-images img{
        width: 100%;
    }

    /* .follwus-section-images img{
        width: 150px;
        margin: 10px;
    } */

}

@media screen and (min-width: 768px) {
    .home-about{
        height: 300px;
    }
    .home-about p{
        width: 580px;
    }
    .Homepage-banner h1{
        margin-top: -18%;
    }
    .carousel-left p{
        font-size: 12px;
    }
    .carousel-left h2{
        font-size: 15px;
        width: 5.45rem;
    }
    .carousel-left-text{
        margin-left: 15%;
    }
    .Gulmoharlotus{
        width: 50%;
    }
    .Pattren1{
        width: 250px;
        z-index: 1;
        position: absolute;
        margin-top: -150px;
        margin-left: -50%;
    }

    /* whyus section */
    .whyus-row-text h2{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: 900;
        font-size: 19px;
        line-height: 40px;
        text-transform: uppercase;
        color: #E4CCCB;
    }
  
}

@media screen and (min-width: 900px) {
  
    .home-about p{
        font-size: 22px;
        line-height: 33px;
    }
    .carousel-left-text{
        margin-top: 11%;
    }

    .Services-section{
        margin-top: -10px;
    }
    /* whyus section */
    .whyus-row-text h2{
        font-size: 25px;
        line-height: 33px;
    }
    .whyus-row-text p{
        font-size: 14px;
    }

    /* Our work section */
    .ourwork-images{
        max-width: 900px;
    }

    /* Testimonial Section */
    .testimonial-text p{
        margin-top: 85px;
        font-size: 15px;
    }
    .Testimonial-carousel-inner{
        width: 800px;
    }

    /* follow Us section */
    .follwus-section-images{
        display: inline-block;
        max-width: 850px;
      
    }

    /* .follwus-section-images img{
        width: 220px;
        margin: 10px;
    } */

}

@media screen and (min-width: 1200px) {
    .Homepage-banner h1{
        font-size: 60px;
        line-height: 80px;
        margin-top: -20%;
    }

    .home-about p{
        width: 730px;
    }
  
    .Pattren1{
        width: 280px;
        z-index: 1;
        position: absolute;
        margin-top: -165px;
        margin-left: -50%;
    }
    .Pattren2{
        width: 300px;
        margin-top: -70px;
    }
  
    .carousel-inner{
       margin-bottom: 150px !important;
    }
    .carousel-left h1{
        font-size: 60px;
        line-height: 80px;
    }
    .carousel-left h2{
        font-size: 25px;
        line-height: 33px;
        width: 9rem;
    }
    .carousel-left p{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 140%;
        color: #063943;
    }
    .carousel-control-prev,.carousel-control-next{
        width: 9% !important;
    }
    .carousel-indicators{
        top: 105%;
    }

    .Services-section{
        margin-top: 2%;
        margin-bottom: -40px;
    }

    /* Why us section */

    .whyus-section{
        height: 520px;
    }
    .whyus-section-content h1{
        font-size: 40px;
    }
    .whyus-section-content{
        margin-top: 35px;
    }

    
    .whyus-row-text p{
        font-size: 17px;
    }
    /* our work section */
    .ourwork-section-content h1{
        font-weight: 900;
        font-size: 40px;
        line-height: 53px;
    }
    .ourwork-icons{
        width: 30px;
        margin-top: -10px;
        margin-left: 30px;
        margin-right: 30px;
    }

    /* Testionoal Section */
    .Testimonial-section{
        height: 399px;
    }
    .Testimonial-carousel{
        top: 50px;
    }
    .Testimonial-carousel-inner{
        height: 300px;
        width: 1000px;
    }

    .Testimonial-section h1{
        font-size: 40px;
        line-height: 53px;
        margin-top: 48px !important;
    }
    .testimonial-text p{
        font-size: 20px;
        margin-top: 130px;
    }
    .testimonial-text h5{
        font-size: 22px;
    }

        /* Follows Us Section */
        .Followus-section{
            width: 100%;
        }
        .Followus-section h1{
            font-size: 40px;
            line-height: 53px;
        }
        .Followus-section p{
            font-size: 20px;
            line-height: 30px;
        }
        .followsus-icons img{
            margin: 15px;
            width: 50px;
            margin-top: -5px;
        }
}

@media screen and (min-width: 1440px) {
    .whyus-section{
        height: 549px;
    }

    .Pattren1{
        width: 370px;
        z-index: 1;
        position: absolute;
        margin-top: -220px;
        margin-left: -51%;
    }

    .home-about{
        height: 387px;
    }
    .Services-section{
        height: 650px !important;
    }
    .carousel-inner{
        height: 550px;
    }
    .Testimonial-carousel-inner{
        height: 300px;
    }

    .whyus-section-content h1{
        margin-top: 40px;
    }

    .whyus-row-text p{
        font-size: 18px;
    }

    .whuys-row{
        padding: 20px;
        width: 95%;
        max-width: 1400px;
        margin: 0 auto;
    }
    .carousel-indicators{
        top: 98%;
    }

    .carousel-right img{
        width: 80%;
     }
}

@media screen and (min-width: 1800px) {
    .Services-section{
        margin-bottom: -10px;
    }
    .carousel-inner{
        height: auto;
    }
    .carousel-right img{
        width: 50%;
    }
    .whyus-row-text p{
        font-size: 19px;
    }
    .carousel-indicators{
        top: 115%;
    }
}

@media screen and (min-width: 2560px) {
    .carousel-indicators{
        top: 100%;
    }
    .Services-section{
        margin-bottom: 100px;
    }
}