@media screen and (max-width: 599px) {
    .contact-us{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px !important;
        line-height: 33px;
        text-transform: uppercase;
        color: #2C4F4B;
        margin-top: 50px;
    }
    .Contact-page-banner{
        background: url('../../Images/ContactusBanner.png');
        background-size: cover;
        width: 100%;
        height: 293px;
        position: relative;
        margin-top: 53px;
    }
    .Contact-page-banner h1{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: bold;
        font-size: 35px;
        line-height: 40px;
        color: #FFFFFF;
        position: relative;
        top: 50%;
        transform: translateY(-50%) !important;
    }
    .contact-row{
        margin-top: 50px !important;
        width: 100%;
        margin: 0 auto;
        padding: 10px;
    }
    .contact-row h3{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 27px;
        color: #2C4F4B;
        text-transform: uppercase;
        text-align: start;

    }
    .contact-row p{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 33px;
        color: #2C4F4B;
        text-align: start;
    }
    .Contact-section h1{
        font-size: 40px;
        line-height: 53px;
    }
    iframe{
        width: 100%;
        height: 200px;
        margin-bottom: 20px;
    }
    .fa-facebook,.fa-pinterest,.fa-instagram-square{
        font-size: 50px;
        color: #2C4F4B;
        margin: 10px;
    }
    .contact-details{
        margin-top: 100px;
    }
}




@media screen and (min-width: 600px) {
    .Contact-page-banner{
        background: url('../../Images/ContactusBanner.png');
        background-size: cover;
        width: 100%;
        height: 345px;
        position: relative;
        margin-top: 53px;
    }
    .Contact-page-banner h1{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: bold;
        font-size: 60px;
        line-height: 80px;
        color: #FFFFFF;
        position: relative;
        top: 50%;
        transform: translateY(-50%) !important;
    }
    .Contact-section{
        margin-top: 20px;
        margin-bottom: 50px;
        width: 100%;
        margin: 0 auto;
    }
    .Contact-section h1{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 33px;
        text-transform: uppercase;
        color: #2C4F4B;
        margin-top: 50px;
    }
    .contact-row{
        width: 95%;
        margin: 0 auto;
    }
    .contact-row h3{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 27px;
        color: #2C4F4B;
        text-transform: uppercase;
        text-align: start;

    }
    .contact-row p{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 33px;
        color: #2C4F4B;
        text-align: start;
    }
    .Contact-section h1{
        font-size: 40px;
        line-height: 53px;
    }
    iframe{
        width: 100%;
        height: 310px;
        margin-top: 0px;
    }
    .fa-facebook,.fa-pinterest,.fa-instagram-square{
        font-size: 50px;
        color: #2C4F4B;
        margin: 15px;
    }
    .contact-details{
        margin-top: 120px;
    }
    .Contact-section{
        margin-top: 5%;
        margin-bottom: 5%;
    }
}

@media screen and (min-width: 900px) {
    .Contact-section{
        width: 90%;
    }
}

@media screen and (min-width: 1200px) {
    .contact-row{
        margin-top: 5%;
        width: 85%;
    }
    .contact-row h3{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 27px;
        color: #2C4F4B;
        text-transform: uppercase;
        text-align: start;
        margin-bottom: 10px;
    }
    .contact-row p{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 33px;
        color: #2C4F4B;
        text-align: start;
    }
    .Contact-section h1{
        font-size: 40px;
        line-height: 53px;
    }
    
    iframe{
        height: 310px;
    }
    .right-contact-text{
        margin-left: 100px;
    }
 
}

@media screen and (min-width: 1440px) {
    .Contact-section{
        width: 1200px;
    }
}