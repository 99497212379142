@media screen and (max-width: 599px) {
    .Work-page-banner{
        background: url('../../Images/Bannerimage2.jpg');
        background-size: cover;
        width: 100%;
        height: 293px;
        position: relative;
    }
    .Work-page-banner h1{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 40px;
        color: #FFFFFF;
        position: relative;
        top: 50%;
        transform: translateY(-50%) !important;
        top: 60%;
    }
    /* .card{
        width: 250px;
        border: none;
    }
    .card-title{
        font-family: Playfair Display;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 33px;
        color: #000000;
        text-align: start;
    }
    .card-text{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 27px;
        color: #000000;
        text-align: start;
    }
    .project-cards{
        display: grid;
        grid-template-columns: repeat(1,0.1fr);
        grid-gap: 50px;
        justify-content: center;
        align-content: center;
        margin-top: 50px !important;
       
        width: 95%;
        margin: 0 auto;
        margin-bottom: 5%;
    } */

    .lightbox-gallery{
        width: 80%;
        margin: 0 auto;
        margin-top: 25px;
    }
    .gallery-images img{
        width: 100%;
        margin-bottom: 30px;
    }
    .lightbox-gallery h1{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 40px;
        text-transform: uppercase;
        color: #2C4F4B;
        margin-bottom: 30px;
    }

    .slider {
        height: 100%;
        width: 1px;
        background: mediumaquamarine;
    }

    .image1{
        width: 90%;
        height: 350px;
    }

    .after-slider{
        width: 90%;
        height: 350px;
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom:50px;
    }
    .before-after-segment h1{
        font-family: 'Playfair Display', serif;
        font-size: 20px;
        line-height: 40px;
        font-weight: bold;
        text-transform: uppercase;
        color: #2C4F4B;
        margin-top: 20px;
    }

    .before-after-segment p{
        font-family: Poppins;
        margin-bottom: -20px;
        font-size: 15px;
        color: #2C4F4B;
    }
}





@media screen and (min-width: 600px) {
    .Work-page-banner{
        background: url('../../Images/Bannerimage2.jpg');
        background-size: cover;
        width: 100%;
        height: 345px;
        position: relative;
        margin-top: 53px;
    }
    .Work-page-banner h1{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: bold;
        font-size: 60px;
        line-height: 80px;
        color: #FFFFFF;
        position: relative;
        top: 50%;
        transform: translateY(-50%) !important;
    }
    /* .card{
        width: 250px;
        border: none;
    }
    .card:hover {
        text-decoration: none !important;
    }
    .card-body:hover {
        text-decoration: none !important;
    }
    .card-title{
        font-family: Playfair Display;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 33px;
        color: #000000;
        text-align: start;
    }
    .card-text{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 27px;
        color: #000000;
        text-align: start;
    }
    .project-cards{
        display: grid;
        grid-template-columns: repeat(2,0.1fr);
        grid-gap: 50px;
        justify-content: center;
        align-content: center;
        margin-top: 50px !important;
       
        width: 95%;
        margin: 0 auto;
        margin-bottom: 5%;
    } */



    /* Gallery section */

    .lightbox-gallery,.lightbox-gallery2{
        width: 80%;
        margin: 0 auto;
        margin-top: 100px;
    }
    .gallery-images img{
        width: 100%;
        margin-bottom: 30px;
        cursor: pointer;
    }
    .lightbox-gallery h1,.lightbox-gallery2 h1{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 40px;
        text-transform: uppercase;
        color: #2C4F4B;
        margin-bottom: 30px;
    }


    /* Before after section */
    .slider {
        height: 100%;
        width: 1px;
        background: mediumaquamarine;
    }

    .image1{
        width: 480px;
        height: 450px;
    }

    .after-slider{
        width: 480px;
        height: 450px;
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom:50px;
    }
    .before-after-segment h1{
        font-family: 'Playfair Display', serif;
        font-size: 20px;
        line-height: 40px;
        font-weight: bold;
        text-transform: uppercase;
        color: #2C4F4B;
        margin-top: 20px;
    }

    .before-after-segment p{
        font-family: Poppins;
        margin-top: 70px;
        font-size: 20px;
        margin-bottom: -45px;
       color: #063943;
    }
   

  
}


@media screen and (min-width: 900px) {
    .image1{
        width: 700px;
        height: 650px;
    }

    .after-slider{
        width: 700px;
        height: 650px;
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom:50px;
    }
}


@media screen and (min-width: 1200px) {
    /* .card{
        width: 250px;
        border: none;
    }
    .card-title{
        font-family: Playfair Display;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 33px;
        color: #000000;
        text-align: start;
    }
    .card-text{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 27px;
        color: #000000;
        text-align: start;
    }
    .project-cards{
        display: grid;
        grid-template-columns: repeat(2,0.1fr);
        grid-gap: 100px;
        justify-content: center;
        align-content: center;
        margin-top: 50px !important;
        width: 80%;
        margin: 0 auto;
        margin-bottom: 5%;
    }
*/
 
    .slider {
        height: 100%;
        width: 1px;
        background: mediumaquamarine;
    }

    .image1{
        width: 700px;
        height: 650px;
    }

    .after-slider{
        width: 700px;
        height: 650px;
        margin: 0 auto;
        margin-top: 100px;
        margin-bottom:100px;
    }
    .before-after-segment h1{
        font-family: 'Playfair Display', serif;
        font-size: 40px;
        line-height: 80px;
        font-weight: bold;
        text-transform: uppercase;
        color: #2C4F4B;
        margin-bottom: -70px;
        margin-top: 100px;
    }

}

@media screen and (min-width: 1440px) {

    .work-carousel{
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .work-carousel-inner{
        height: 70vh;
    }
    .lightbox-gallery,.lightbox-gallery2{
        width: 80%;
        margin: 0 auto;
        margin-top: 100px;
    }
    .gallery-images img,.gallery-images2 img{
        width: 100%;
        margin-bottom: 30px;
    }
    .lightbox-gallery h1,.lightbox-gallery2 h1{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 80px;
        text-transform: uppercase;
        color: #2C4F4B;
        margin-bottom: 30px;
    }

}