@media screen and (max-width: 599px) {
    .Process-Banner{
        width: 100%;
        height: 293px;
        background: url('../../Images/MobileProcessBanner.svg');
        background-size: cover;
    }
    .Process-Banner h1{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 40px;
        color: #FFFFFF;
        position: relative;
        top: 60%;
        transform: translateY(-50%);
    }
    .Process-desc{
        width: 100%;
        background: url('../../Images/About\ Us\ Section.png');
        height: 230px;
        padding: 20px;
        margin-bottom: 50px;
    }
    .Process-desc p{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 130%;
        text-align: center;
        color: #063943;
        width: 93%;
        margin: 0 auto;
        margin-top: -15px;
    }
    .Process-desc-text{
        top: 50%;
        transform: translateY(-50%);
        position: relative;
        margin-top: -10px;
    }
    .Process1{
        margin-top: -120px;
        margin-bottom: 10%;
    }
    .image-section img{
       width: 120px;
    }
  
    .image-section{
        width: 100%;
    }

    .Process1 h3,.Process2 h3{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 130%;
        text-align: start;
        color: #2C4F4B;
        text-transform: uppercase;
    }
    .Process1 p,.Process2 p{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 130%;
        color: #063943;
        text-align: start;
    }
    .Process1-right-text,.Process2-right-text{
        margin-top: 10px;
        margin-left: -25px;
    }
    .Process1-row{
        width: 69%;
        margin: 0 auto;
    }

    .Process1{
        margin-bottom: 25%;
    }

    .Process1-right-text{
        margin-left: 0%;
    }

    /* Process2 */
    .Process2{
        background-image: url('../../Images/About\ Us\ Section.png');
        height: 227px;
    }
    .Process2{
        margin-bottom: 20%;
    }
    .image-section2 img{
        width: 174px;
    }
    .Process2-row{
        width: 100%;
        margin: 0 auto;
    }
    .image-section2{
        width: 100%;
        margin-top: -62px;
    }
    .Process2-right-text{
        margin-left: 15%;
    }


    .Hr-line,.Hr-line2{
        display: none;
    }
    .last-process{
        margin-bottom: 0px !important;
    }

   .first-process{
       margin-top: -20%;
    }

    .process-pattren1,.process-pattren2,.process-pattren3,.process-pattren4{
        display: none;
    }
   
}

@media screen and (max-width: 425px) {
    .Process1-row{
        width: 75%;
    }
}

@media screen and (max-width: 375px) {
    .Process1-row,.Process2-row{
        width: 80%;
    }
    .Process2-right-text{
        margin-left: 0%;
    }
}



@media screen and (min-width: 600px) {
    .Process-Banner{
        width: 100%;
        height: 345px;
        background: url('../../Images/ProcessBanner.svg');
        background-size: cover;
        margin-top: 53px;
    }
    .Process-Banner h1{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 50px;
        color: #FFFFFF;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
    .Process-desc{
        width: 100%;
        background: url('../../Images/About\ Us\ Section.png');
        height: 300px;
        padding: 20px;
        margin-bottom: 15%;
    }
    .Process-desc p{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 130%;
        text-align: center;
        color: #063943;
    }
    .Process-desc-text{
        top: 50%;
        transform: translateY(-50%);
        position: relative;
    }
 
    .image-section img{
        width: 200px;
    }
  
    .image-section{
        width: 100%;
    }

    .Process1 h3,.Process2 h3{
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 130%;
        text-align: start;
        color: #2C4F4B;
        text-transform: uppercase;
    }
    .Process1 p,.Process2 p{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        color: #063943;
        text-align: start;
    }
    .Process1-right-text,.Process2-right-text{
        margin-top: 10px;
        margin-left: -25px;
    }
    .Process1-row{
        width: 400px;
        margin: 0 auto;
    }

    .Process1{
        margin-top: -23%;
    }
    .Process1{
        margin-bottom: 20%;
    }

    .Process2{
        margin-bottom: 15%;
    }

    /* Process2 */
   
    .Process2-row{
        width: 380px;
        margin: 0 auto;
    }
    .image-section2{
        width: 100%;
        margin-top: -85px;
    }
    .image-section2 img{
      width: 250px;
    }

    .Process2{
        background-image: url('../../Images/About\ Us\ Section.png');
        height: 297px;
    }

    .last-process{
        margin-bottom: 0px;
    }
   
    .Process2-right-text{
        margin-top: 5px;
    }

    .Hr-line,.Hr-line2{
        display: none;
    }
    
    .Hr-line2{
        border: 5px solid #E4CCCA;
        position: absolute;
        z-index: 1;
        margin-top: 200px;
        margin-left: 290px;
        width: 150px;
    }
    .styling-process{
        margin-top: -20% !important;
    }


    /* pattren process */
 
    .process-pattren1 img{
        width: 100%;
    }
    .process-pattren1{
        height: 50px;
        margin-top: -50%;
        margin-left: 68.8%;
    }
    .process-pattren2{
        height: 50px;
        margin-top: -76.5%;
        margin-left: 8%;
    }
    .process-pattren3{
        height: 50px;
        margin-top: -55.5%;
        margin-left: 25%;
    }
    .process-pattren4{
        height: 50px;
        margin-top: -50%;
        margin-left: 67.5%;
    }
  
   
}

@media screen and (min-width: 768px) {
    .Process1,.Process2{
        margin-bottom: 16%;
    }
    .last-process{
        margin-bottom: 0px !important;
    }
}


@media screen and (min-width: 900px) {
  
    .Process-desc-text{
        width: 850px;
        margin: 0 auto;
    }
    .Process-desc-text p{
        font-size: 16px;
    }
    .Process1-row{
        width: 700px;
    }

    .Process1-right-text{
        padding: 0px;
        margin-top: -90px;
        margin-left: 53%;
    }
    .image-section{
        width: 40%;
    }

    .image-section img{
        width: 250px;
        height: 333px;
    }
    .image-section2 img{
        width: 358px !important;
    }

    /* Process2 */
    .Process2-row{
        width: 900px;
        margin: 0 auto;
    }
    .image-section2{
        width: 365px;
        height: 269px;
    }
    .image-section2 img{
        float: right;
    }
    .Process2-right-text{
        margin-left: 100px;
        margin-top: 95px;
    }

    .Hr-line{
        display: block;
        border: 5px solid #E4CCCA;
        width: 150px;
        margin-top: -55px;
        margin-left: 200px;
        position: absolute;
        z-index: 1;
    }
    .Hr-line2{
        display: block;
        border: 5px solid #E4CCCA;
        position: absolute;
        z-index: 1;
        margin-top: 220px;
        margin-left: 290px;
        width: 150px;
    }
    .Process1,.Process2{
        margin-bottom: 16%;
    }
    .Process1{
        margin-top: -20%;
    }
    .image-section2 {
        margin-top: -10%;
    }
    .last-process{
        margin-bottom: 0px !important;
    }
    .last-process-text{
        margin-top: 65px;
    }
    .Hr-line3{
        margin-top: 190px;
    }

    /* Pattren process */
    
    .process-pattren1{
        height: 50px;
        margin-top: 11%;
        margin-left: -5.7%;
    }
    .process-pattren2{
        height: 50px;
        margin-top: -5.6%;
        margin-left: -47%;
    }
    .process-pattren3{
        height: 50px;
        margin-top: -12.7%;
        margin-left: -35%;
    }
    .process-pattren4{
        height: 50px;
        margin-top: 11%;
        margin-left: -6.5%;
    }
    .process3-text{
        margin-left: 40%;
        margin-top: 93px;
    }
   


}

@media screen and (min-width: 1200px) {
    .Process-Banner h1{
        font-size: 60px;
        line-height: 80px;
    }
    .Process-desc-text p{
        font-size: 18px;
    }
    .Process1{
        margin-bottom: 200px;
        margin-top: -170px
    }
    .Process2{
        margin-top: 0px;
        margin-bottom: 100px;
    }
    .first-image-section{
        margin-bottom: 20px !important;
        margin-top: 15.5% !important;
    }
    .image-section img{
        width: 250px;
        height: 333.58px;
    }
    
    .image-section2 {
        margin-top: -115px;
        margin-bottom: 0px;
    }
    .image-section2 img{
        width: 365px;
        height: 269px;
    }
    .Process-desc{
        margin-bottom: 0px;
    }
    .One-text{
        margin-top: -115px !important;
    }
    .Process1-right-text{
        margin-top: -95px;
    }
    .Process2-right-text{
        margin-top: 57px;
    }
    .Process1-right-text p,.Process2-right-text p{
        font-size: 18px;
    }
    .Process1-row{
        width: 840px;
    }

    .Hr-line2{
        margin-top: 210px;
    }
    .image-section{
        margin-left: 70px;
    }

    .process-pattren3{
        height: 50px;
        margin-top: -17.2%;
        margin-left: -35%;
    }

    .process-pattren1{
        height: 50px;
        margin-top: 11%;
        margin-left: -6.4%;
    }
    .process-pattren2{
        margin-top: -5.6%;
        margin-left: -46%;
    }
  
    .process-pattren4{
        margin-top: 11%;
        margin-left: -7.3%;
    }
}